import React from "react";
import { IntlProvider } from "react-intl";

// eslint-disable-next-line react/display-name,react/prop-types
const wrap = ({ element }) => {
  return (
    <IntlProvider locale="fi" defaultLocale="fi">
      {element}
    </IntlProvider>
  );
};

export default wrap
